import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import { fetchData } from './redux/data/dataActions';
import * as s from './styles/globalStyles';
import styled, { withTheme } from 'styled-components';
import { SocialIcon } from 'react-social-icons';

const truncate = (input, len) =>
	input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
	margin: 30px;
	border: solid;
	background-color: Black;
	padding: 10px;
	font-weight: bold;
	color: var(--secondary-text);
	width: 100px;
	cursor: pointer;
`;

export const StyledRoundButton = styled.button`
	padding: 20px;
	border: none;
	background-color: var(--primary);
	padding: 20px;
	font-weight: bold;
	font-size: 15px;
	color: var(--primary-text);
	width: 30px;
	height: 30px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	-moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
	:active {
		box-shadow: none;
		-webkit-box-shadow: none;
		-moz-box-shadow: none;
	}
`;

export const ResponsiveWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: stretched;
	align-items: stretched;
	width: 100%;
	@media (min-width: 767px) {
		flex-direction: row;
	}
`;

export const StyledLogo = styled.img`
	@media (max-width: 900px) {
		width: 10%;
		height: auto;
		bottom: 0;
		margin-top: 10%;
	}
	@media (min-width: 1000px) {
		width: 5%;
		margin-left: 20px;
		position: absolute;
	}
	transition: width 0.5s;
	transition: height 0.5s;
`;

export const StyledImg = styled.img`
	background-color: var(--accent);
	@media (max-width: 900px) {
		margin-top: 15%;
		width: 60%;
		height: auto;
		top: 0;
		bottom: 0;
	}
	@media (min-width: 1000px) {
		width: 90%;
		height: auto;
		margin-left: 90%;
		margin-top: 20%;
	}
	transition: width 0.5s;
`;

export const StyledLink = styled.a`
	color: var(--secondary);
	text-decoration: none;
`;

export default function Mint() {
	const dispatch = useDispatch();
	const blockchain = useSelector((state) => state.blockchain);
	const data = useSelector((state) => state.data);
	const [claimingNft, setClaimingNft] = useState(false);
	const [feedback, setFeedback] = useState(
		`Click MINT to purchase your collection`
	);
	const [mintAmount, setMintAmount] = useState(1);
	const [CONFIG, SET_CONFIG] = useState({
		CONTRACT_ADDRESS: '',
		SCAN_LINK: '',
		NETWORK: {
			NAME: '',
			SYMBOL: '',
			ID: 0,
		},
		NFT_NAME: '',
		SYMBOL: '',
		MAX_SUPPLY: 1,
		WEI_COST: 0,
		DISPLAY_COST: 0,
		GAS_LIMIT: 0,
		MARKETPLACE: '',
		MARKETPLACE_LINK: '',
		SHOW_BACKGROUND: false,
	});

	const claimNFTs = () => {
		let cost = CONFIG.WEI_COST;
		let gasLimit = CONFIG.GAS_LIMIT;
		let totalCostWei = String(cost * mintAmount);
		let totalGasLimit = String(gasLimit * mintAmount);
		console.log('Cost: ', totalCostWei);
		console.log('Gas limit: ', totalGasLimit);
		setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
		setClaimingNft(true);
		blockchain.smartContract.methods
			.mint(mintAmount)
			.send({
				gasLimit: String(totalGasLimit),
				to: CONFIG.CONTRACT_ADDRESS,
				from: blockchain.account,
				value: totalCostWei,
			})
			.once('error', (err) => {
				console.log(err);
				setFeedback('Sorry, something went wrong please try again later.');
				setClaimingNft(false);
			})
			.then((receipt) => {
				console.log(receipt);
				setFeedback(`Please visit opensea.io to view your collection`);
				setClaimingNft(false);
				dispatch(fetchData(blockchain.account));
			});
	};

	const decrementMintAmount = () => {
		let newMintAmount = mintAmount - 1;
		if (newMintAmount < 1) {
			newMintAmount = 1;
		}
		setMintAmount(newMintAmount);
	};

	const incrementMintAmount = () => {
		let newMintAmount = mintAmount + 1;
		if (newMintAmount > CONFIG.MAX_MINT) {
			newMintAmount = CONFIG.MAX_MINT;
		}
		setMintAmount(newMintAmount);
	};

	const getData = () => {
		if (blockchain.account !== '' && blockchain.smartContract !== null) {
			dispatch(fetchData(blockchain.account));
		}
	};

	const getConfig = async () => {
		const configResponse = await fetch('/config/config.json', {
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		});
		const config = await configResponse.json();
		SET_CONFIG(config);
	};

	useEffect(() => {
		getConfig();
	});

	useEffect(() => {
		getData();
	}, [blockchain.account]);

	return (
		<s.Screen>
			<div class='navbar' style={{ backgroundColor: 'white' }}>
				<ul>
					<li>
						<s.Container
							jc={'right'}
							ai={'right'}
							style={{
								width: '100%',
								flexDirection: 'row',
								position: 'right',
								//marginTop: '2%',
							}}
						>
							<a
								href='https://discord.gg/8BhNeVDPAn'
								target='_blank'
								style={{
									top: '15px',

									left: '15px',
									width: '5%',
								}}
							>
								<img
									style={{ width: '100%' }}
									src='/config/images/Discord.png'
								/>
							</a>
							<a
								href='https://www.instagram.com/crybabiesnft/'
								target='_blank'
								style={{
									top: '15px',

									left: '15px',
									width: '5%',
								}}
							>
								<img
									style={{ width: '100%' }}
									src='/config/images/Instagram.png'
								/>
							</a>
							<a
								href='https://opensea.io/collection/cry-babies-collection'
								target='_blank'
								style={{
									top: '15px',

									left: '15px',
									width: '5%',
								}}
							>
								<img
									style={{ width: '100%' }}
									src='/config/images/OpenSeaa.png'
								/>
							</a>
							<a
								href='https://twitter.com/CryBabiesNFT'
								target='_blank'
								style={{
									top: '15px',

									left: '15px',
									width: '5%',
								}}
							>
								<img
									style={{ width: '100%' }}
									src='/config/images/Twitterr.png'
								/>
							</a>
						</s.Container>
					</li>
				</ul>
			</div>
			<StyledLogo
				alt={'logo'}
				src={'/config/images/cry_babies_logo.png'}
				style={{
					width: '4%',
					position: 'left',
					marginTop: '0.5%',
				}}
			/>
			<s.Container
				flex={1}
				ai={'center'}
				style={{ padding: 0, backgroundColor: 'black' }}
			>
				<ResponsiveWrapper flex={1} style={{ padding: 0 }} test>
					<s.Container flex={1} jc={'center'} ai={'center'}>
						<StyledImg alt={'example'} src={'/config/images/NFT_Gif.gif'} />
					</s.Container>
					<s.SpacerLarge />

					<s.Container
						flex={2}
						jc={'center'}
						ai={'center'}
						style={{
							marginTop: '10%',
							borderRadius: 0,
						}}
					>
						<s.TextTitle
							class='Ftext'
							style={{
								textAlign: 'center',
								fontSize: 20,
								position: 'reative',
								color: 'var(--accent-text)',
								marginTop: '10px',
								textTransform: 'uppercase',
								fontWeight: '100',
								textDecoration: 'none',
								color: 'white',
								fontFamily: 'Segoe UI',
							}}
						>
							Price per Cry Baby
						</s.TextTitle>

						<s.TextTitle
							class='Ftext'
							style={{
								textAlign: 'center',
								fontSize: 50,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
							}}
						>
							{CONFIG.DISPLAY_COST} {CONFIG.NETWORK.SYMBOL}{' '}
						</s.TextTitle>
						<s.TextTitle
							class='Ftext'
							style={{
								textAlign: 'center',
								fontSize: 20,
								position: 'reative',
								color: 'var(--accent-text)',
								marginTop: '10px',
								textTransform: 'uppercase',
								fontWeight: '100',
								textDecoration: 'none',
								color: 'white',
								fontFamily: 'Segoe UI',
							}}
						>
							Cry babies collection
						</s.TextTitle>
						<s.TextTitle
							class='Ftext'
							style={{
								textAlign: 'center',
								fontSize: 50,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
							}}
						>
							{data.totalSupply} / {CONFIG.MAX_SUPPLY}
						</s.TextTitle>
						<s.SpacerSmall />
						{Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
							<>
								<s.TextTitle
									style={{ textAlign: 'center', color: 'var(--accent-text)' }}
								>
									The sale has ended.
								</s.TextTitle>
								<s.TextDescription
									style={{ textAlign: 'center', color: 'var(--accent-text)' }}
								>
									Welcome to cry Babies.
								</s.TextDescription>
								<s.SpacerSmall />
								<StyledLink target={'_blank'} href={CONFIG.MARKETPLACE_LINK}>
									{CONFIG.MARKETPLACE} to view it.
								</StyledLink>
							</>
						) : (
							<>
								>
								<s.SpacerSmall />
								{blockchain.account === '' ||
								blockchain.smartContract === null ? (
									<s.Container ai={'center'} jc={'center'}>
										<StyledButton
											onClick={(e) => {
												e.preventDefault();
												dispatch(connect());
												getData();
											}}
										>
											CONNECT
										</StyledButton>
										{blockchain.errorMsg !== '' ? (
											<>
												<s.SpacerSmall />
												<s.TextDescription
													style={{
														textAlign: 'center',
														color: 'var(--accent-text)',
													}}
												>
													{blockchain.errorMsg}
												</s.TextDescription>
											</>
										) : null}
									</s.Container>
								) : (
									<>
										<s.TextDescription
											style={{
												textAlign: 'center',
												color: 'var(--accent-text)',
											}}
										>
											{feedback}
										</s.TextDescription>
										<s.SpacerMedium />
										<s.Container ai={'center'} jc={'center'} fd={'row'}>
											<StyledRoundButton
												style={{ lineHeight: 0.4 }}
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													decrementMintAmount();
												}}
											>
												-
											</StyledRoundButton>
											<s.SpacerMedium />
											<s.TextDescription
												style={{
													textAlign: 'center',
													color: 'var(--accent-text)',
												}}
											>
												{mintAmount}
											</s.TextDescription>
											<s.SpacerMedium />
											<StyledRoundButton
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													incrementMintAmount();
												}}
											>
												+
											</StyledRoundButton>
										</s.Container>
										<s.SpacerSmall />
										<s.Container ai={'center'} jc={'center'} fd={'row'}>
											<StyledButton
												disabled={claimingNft ? 1 : 0}
												onClick={(e) => {
													e.preventDefault();
													claimNFTs();
													getData();
												}}
											>
												{claimingNft ? 'BUSY' : 'MINT'}
											</StyledButton>
										</s.Container>
									</>
								)}
							</>
						)}
						<s.SpacerMedium />
						<s.SpacerMedium />
					</s.Container>
					<s.SpacerLarge />
					{/* here image  */}
				</ResponsiveWrapper>
				<s.SpacerSmall />
				<s.Container
					jc={'center'}
					ai={'center'}
					style={{ backgroundColor: 'black' }}
				>
					<s.Container
						jc={'center'}
						ai={'left'}
						style={{ width: '50%', marginTop: '10%' }}
					>
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 70,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							ABOUT CRY BABIES
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							The Cry Babies Collection is composed of 9,999 babies forever
							crying on the Ethereum Blockchain. Containing almost two hundred
							hand drawn attributes, including 20 one of one Special Editions.
						</s.TextDescription>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							Philadelphia artist Jose Garcia, presents us with an NFT
							collection that mixes total freedom of expression with art that
							encapsulates human culture, comedy, tragedy and beauty. All
							perceived through the eyes of a child.
						</s.TextDescription>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							The Cry Babies Collection is a genesis drop and will also act as a
							mint pass for future projects. Holding a Cry Baby gives you access
							to our community and can enter activities, games, contests, events
							and more.
						</s.TextDescription>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							For more information on The Cry Babies Collection follow us on
							Twitter and join our Discord for announcements
							and updates.
						</s.TextDescription>
						<s.SpacerSmall />

						<s.SpacerMedium />
					</s.Container>
				</s.Container>
				<s.Container
					jc={'center'}
					ai={'center'}
					style={{ backgroundColor: 'black', marginTop: '10%' }}
					image={'/config/images/24783.jpg'}
				>
					<s.Container
						jc={'center'}
						ai={'left'}
						style={{ width: '50%', marginTop: '10%' }}
					>
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Cry Baby Community
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							Welcome to our community. Holding a Cry Baby gives you a mint pass
							and amenities for future exciting projects that will be released.
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Contests & Activities
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							Join our discord to be a part of the many art contests and
							activities we will be hosting. The winners chosen will receive
							gifts, NFTs, merchandise and a chance to have your artwork
							presented in our Art Gallery in the Sandbox Metaverse!
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							The Metaverse
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							Upon sellout of the collection, we will use profits made to invest
							into land inside the Sandbox Metaverse! This land will be used to
							create a virtual art gallery to showcase some of the best NFT
							artworks made by our community.
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							VIP Merchandise Drop
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							Our online shop will open to be available for all Cry Baby
							holders. Prints, clothing and accessories will be available for
							purchase at our store. Do you want a chance to have your very own
							Cry Baby NFT on a T-Shirt? Following the VIP Merchandise Drop, we
							will choose 100 lucky holders to have their own Cry Baby printed
							on a shirt and sent out to them.
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							Charitable Donations
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							The art for this project wasn’t made from random ideas, it has a
							deeper meaning to that. When you mint our NFTs, not only are you
							supporting our project, but you’re supporting a cause. This is why
							we are donating a portion of all primary sales to a brain cancer
							awareness charity that will be chosen by our great Cry Baby
							Community!
						</s.TextDescription>
						<s.SpacerSmall />
						<s.TextTitle
							style={{
								textAlign: 'center',
								fontSize: 30,
								fontWeight: 'bold',
								color: 'var(--accent-text)',
								textTransform: 'uppercase',
							}}
						>
							The Future
						</s.TextTitle>
						<s.SpacerMedium />
						<s.TextDescription
							style={{
								textAlign: 'left',
								color: 'var(--primary-text)',
							}}
						>
							It doesn’t just stop at Cry Babies. Our team is obsessed with the
							NFT space and we plan to bring more exciting, innovative, and
							artistic projects to the NFT Community. Stay tuned!
						</s.TextDescription>
						<s.SpacerMedium />
						<s.SpacerMedium />
					</s.Container>
				</s.Container>

				<img src='/config/images/The_founders.png' />
			</s.Container>
		</s.Screen>
	);
}
